.sprechblase{
    width: 55px;
    height: auto;
    margin-top: 10px;
    margin-left: 10px;
    float: left;
}

.questblase{
    width: 55px;
    height: auto;
    /* margin-top: 38px; */
    /* margin-left: 30px; */
    margin-left: 5px;
    float: left;
}

.sprechblase-button{
    width: 50px;
    height: auto;
}

.stanceModal {
    position: fixed;
    min-width: 60vw;
    height: auto;
    z-index: 999999999;
    top: 8%;
    left: 12%;
    /* top: 50%; */
    /* left: 35%; */
    background: white;
    border: 1px solid black;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    left: 47%;
    transform: translateX(-45%);

  &.red {
    background: black;
    color: red;
  }
  &.blue {
    background: blue;
    color: red;
  }

  &.off {
    opacity: 0;
    visibility: hidden;
    filter: blur(8px);
    transform: scale(0.33);
    box-shadow: 1rem 0 0 rgba(black, 0.2);
  }

  @media (prefers-reduced-motion) {
    offset-path: none;
  }
  h2 {
    border-bottom: 1px solid #ccc;
    padding: 1rem;
    margin: 0;
  }
  .content {
    padding: 1rem;
  }
  .actions {
    border-top: 1px solid #ccc;
    background: #eee;
    padding: 0.5rem 1rem;
    button {
      border: 0;
      background: #78f89f;
      border-radius: 5px;
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      line-height: 1;
    }
  }
}

.firstStanceModal {
    position: fixed;
    min-width: 60vw;
    height: auto;
    z-index: 999999999;
    top: 10%;
    left: 12%;
    /* top: 50%; */
    /* left: 35%; */
    background: white;
    border: 1px solid black;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    left: 47%;
    transform: translateX(-45%);

  &.red {
    background: black;
    color: red;
  }
  &.blue {
    background: blue;
    color: red;
  }

  &.off {
    opacity: 0;
    visibility: hidden;
    filter: blur(8px);
    transform: scale(0.33);
    box-shadow: 1rem 0 0 rgba(black, 0.2);
  }

  @media (prefers-reduced-motion) {
    offset-path: none;
  }
  h2 {
    border-bottom: 1px solid #ccc;
    padding: 1rem;
    margin: 0;
  }
  .content {
    padding: 1rem;
  }
  .actions {
    border-top: 1px solid #ccc;
    background: #eee;
    padding: 0.5rem 1rem;
    button {
      border: 0;
      background: #78f89f;
      border-radius: 5px;
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      line-height: 1;
    }
  }
}


.questModal {
    position: fixed;
    min-width: 55vw;
    height: auto;
    z-index: 10000000000000000000000;
    top: 17%;
    /* top: 50%; */
    /* left: 35%; */
    background: white;
    border: 1px solid black;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    filter: blur(0);
    opacity: 1;
    visibility: visible;
    left: 47%;
    transform: translateX(-45%);

  &.red {
    background: black;
    color: red;
  }
  &.blue {
    background: blue;
    color: red;
  }

  &.off {
    opacity: 0;
    visibility: hidden;
    filter: blur(8px);
    transform: scale(0.33);
    box-shadow: 1rem 0 0 rgba(black, 0.2);
  }

  @media (prefers-reduced-motion) {
    offset-path: none;
  }
  h2 {
    border-bottom: 1px solid #ccc;
    padding: 1rem;
    margin: 0;
  }
  .content {
    padding: 1rem;
  }
  .actions {
    border-top: 1px solid #ccc;
    background: #eee;
    padding: 0.5rem 1rem;
    button {
      border: 0;
      background: #78f89f;
      border-radius: 5px;
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      line-height: 1;
    }
  }
}

.closedButton{
    width: 50px;
    height: auto;
    float: right;
    position: relative;
    z-index: 999;
}


#centered-toggle-button {
  position: absolute;
}

.gdModal {
  position: fixed;
  min-width: 55vw;
  width: 90%;
  height: auto;
  z-index: 100000000000000000000000;
  top: 10%;
  /* top: 50%; */
  /* left: 35%; */
  background: white;
  border: 1px solid black;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  filter: blur(0);
  opacity: 1;
  visibility: visible;
  left: 47%;
  transform: translateX(-45%);

&.red {
  background: black;
  color: red;
}
&.blue {
  background: blue;
  color: red;
}

&.off {
  opacity: 0;
  visibility: hidden;
  filter: blur(8px);
  transform: scale(0.33);
  box-shadow: 1rem 0 0 rgba(black, 0.2);
}

@media (prefers-reduced-motion) {
  offset-path: none;
}
h2 {
  border-bottom: 1px solid #ccc;
  padding: 1rem;
  margin: 0;
}
.content {
  padding: 1rem;
}
.actions {
  border-top: 1px solid #ccc;
  background: #eee;
  padding: 0.5rem 1rem;
  button {
    border: 0;
    background: #78f89f;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    line-height: 1;
  }
}
}

.faqModal {
  position: fixed;
  min-width: 55vw;
  width: 90%;
  height: auto;
  z-index: 10000000000000000000000;
  top: 7%;
  /* top: 50%; */
  /* left: 35%; */
  background: white;
  border: 1px solid black;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  filter: blur(0);
  opacity: 1;
  visibility: visible;
  left: 47%;
  transform: translateX(-45%);

&.red {
  background: black;
  color: red;
}
&.blue {
  background: blue;
  color: red;
}

&.off {
  opacity: 0;
  visibility: hidden;
  filter: blur(8px);
  transform: scale(0.33);
  box-shadow: 1rem 0 0 rgba(black, 0.2);
}

@media (prefers-reduced-motion) {
  offset-path: none;
}
h2 {
  border-bottom: 1px solid #ccc;
  padding: 1rem;
  margin: 0;
}
.content {
  padding: 1rem;
}
.actions {
  border-top: 1px solid #ccc;
  background: #eee;
  padding: 0.5rem 1rem;
  button {
    border: 0;
    background: #78f89f;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    line-height: 1;
  }
}
}

.faqblase{
  width: 55px;
  height: auto;
  /* margin-top: 38px; */
  /* margin-left: 30px; */
  padding: 7px;
  float: left;
}

.gdblase{
  width: 55px;
  height: auto;
  /* margin-top: 38px; */
  /* margin-left: 30px; */
  padding: 7px;
  float: left;
}