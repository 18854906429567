$spacer-xl: 7.5rem;

.homepage__wrapper {
    background-color: $bg-light;
    margin: 0 $r-spacer 0 $r-spacer;

    @media screen and (min-width: $breakpoint-xxl) {
        max-width: $breakpoint-xxl;
        margin: auto;
    }

    .block {
        p {
            font-size: $font-size-md;
        }
    }
}

// single col cta styling - for headline
.homepage__wrapper .block-row-1 {
    background-color: $body-bg;

    @media screen and (min-width: $breakpoint-xs) {
        padding-top: $block-padding;
    }

    @media screen and (min-width: $breakpoint-md) {
        padding-top: $block-padding-md;
    }
}

.homepage__wrapper .block-row-3 {
    padding: $padding;
    background-color: $body-bg;

    @media screen and (min-width: $breakpoint-xs) {
        padding: $padding $block-padding $block-padding $block-padding;
    }

    @media screen and (min-width: $breakpoint-md) {
        padding: $padding $block-padding-md $block-padding-md $block-padding-md;
    }
}

.homepage__wrapper .block-img-col-2 {
    .rich-text,
    .block-col_cta-content {
        padding: 2 * $padding $padding 0 2 * $padding;

        @media screen and (min-width: $breakpoint-md) {
            padding-right: $block-padding-md;
        }

        @media screen and (min-width: $breakpoint-xl) {
            padding-left: 3 * $padding;
        }
    }
}

.homepage__wrapper .block-img-col__image {
    width: 100%;
    position: relative;
    right: -$spacer;
    margin-top: $r-spacer;

    @media screen and (min-width: $breakpoint-xxl) {
        margin-top: 2 * $spacer;
        right: 3 * -$spacer;
    }
}

.homepage-header {
    margin-bottom: 2 * $r-spacer;

    @media screen and (min-width: $breakpoint-xs) {
        margin-bottom: $spacer-xl;
    }
}

.homepage-header__image-wrapper {
    display: flex;
    justify-content: flex-end;
}

.homepage-header__image {
    position: relative;
    right: -$spacer;
    width: 100%;
    object-fit: cover;

    @media screen and (min-width: $breakpoint-xxl) {
        right: 3 * -$spacer;
    }
}

.homepage-header__teaser {
    font-family: $font-family-serif;
    font-weight: normal;

    b {
        font-family: $font-family-base;
    }
}
