.collapse2 {
  overflow: hidden;
  transition: 0.8s ease max-height;
}

.collapse2 > div {
  overflow: auto;
}

.introVideo{
    position: relative;
    margin-left: 5%;
    width: 100%;
    height: 480px;
}

.buttonBox{
    position: fixed;
    bottom: 1%;
    left: 2%;
    z-index: 9999;
}

.buttonBox2{
    position: fixed;
    bottom: 1%;
    right: 2%;
    z-index: 9999;
}

.argumentText{
    font-size: 1.8vh;
    color: red;
    /* padding: 40px; */
    padding-top: 10px;
    /* margin-right: 50px; */
    padding-bottom: 10px;
}


.forButton {
    padding: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    background: lightgreen;
    color: white;
    width: 120px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    float: left;
    transition: background-color 1s;
    display: block;
    font-size: 20px;
}

.againstButton {
    padding: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    background: red;
    color: white;
    width: 120px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    float: left;
    transition: background-color 1s;
    display: block;
    font-size: 20px;
}

.questText{
    font-size: 24px;
    border-left: 5px solid;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}

.questBox{
    background-color: white;
    padding: 10px;
    overflow: hidden;
    border-radius: 4px;
}

.questButton {
    padding: 10px;
    /*margin-right:20px;*/
    margin-bottom:20px;
    display: block;
    background: darkorange;
    color: white;
    width: 70px;
    height: 70px;
    border-radius: 15px;
    cursor: pointer;
    float:left;
    transition:
    background-color 1s;
}

.questButtonModal {
    padding: 10px;
    margin-right: 90px;
    margin-bottom: 20px;
    margin-top: 10px;
    display: block;
    background: darkorange;
    color: white;
    width: 180px;
    height: auto;
    /* border-radius: 15px; */
    cursor: pointer;
    /* float: left; */
    transition: background-color 1s;
}

.questButton:hover, .questButton:focus-within {
  background-color: orange;
}

.questButtonModal:hover, .questButtonModal:focus-within {
  background-color: orange;
}

.feedButton {
    position: relative;
    left: 30px;
    padding: 10px;
    margin-right:20px;
    margin-bottom:20px;
    display: block;
    background: darkgray;
    color: white;
    width: 70px;
    height: 70px;
    border-radius: 15px;
    cursor: pointer;
    float:left;
    transition:
    background-color 1s;
}

.feedButtonModal {
    padding: 10px;
    margin-right: 90px;
    margin-bottom: 20px;
    margin-top: 10px;
    display: block;
    background: darkgray;
    color: white;
    width: 180px;
    height: auto;
    /* border-radius: 15px; */
    cursor: pointer;
    /* float: left; */
    transition: background-color 1s;
}

.feedButton:hover, .feedButton:focus-within {
  background-color: lightgray;
}

.feedButton:hover, .feedButton:focus-within {
  background-color: lightgray;
}

.faqText{
    font-size: 24px;
    border-left: 5px solid;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}

.faqBox{
    background-color: white;
    padding: 10px;
    overflow: hidden;
    border-radius: 4px;
}

.faqButton {
    padding: 10px;
    /*margin-right:20px;*/
    margin-bottom:20px;
    margin-left: 10px;
    display: block;
    background: teal;
    color: white;
    width: 70px;
    height: 70px;
    border-radius: 40px;
    cursor: pointer;
    float:left;
    transition:
    background-color 1s;
}

.faqButtonModal {
    padding: 10px;
    margin-right: 90px;
    margin-bottom: 20px;
    margin-top: 10px;
    display: block;
    background: teal;
    color: white;
    width: 180px;
    height: auto;
    /* border-radius: 15px; */
    cursor: pointer;
    /* float: left; */
    transition: background-color 1s;
}

.faqButton:hover, .faqButton:focus-within {
  background-color: magenta;
}

.faqButtonModal:hover, .faqButtonModal:focus-within {
  background-color: magenta;
}


.stanceText{
    height: 150px;
    overflow: scroll;
    font-size: 2vh;
    border-left: 5px solid;
    padding: 10px;
    margin-bottom: 10px;
    background-color: gainsboro;
}

.stanceButton {
    padding: 10px;
    margin-right:20px;
    margin-bottom:20px;
    background: steelblue;
    color: white;
    width: 70px;
    height: 70px;
    border-radius: 15px;
    cursor: pointer;
    float:left;
    transition:
    background-color 1s;
    display: block;
}

.stanceBox{
    background-color: white;
    padding-bottom: 20px;
    overflow: hidden;
    border-radius: 4px;
}

.stanceButton:hover, .stanceButton:focus-within {
    background-color: lightsteelblue;
  }
  
.stanceButton .hide{
    display: none;
}


