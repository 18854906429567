.header-lower__position {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.header-lower__nav {
    list-style: none;
    display: flex;
    justify-content: space-between;
}

.header-lower__logo-box {
    display: inline-block;
    padding: 0.5 * $padding;
    position: relative;
    bottom: -3 * $spacer;

    background-color: $body-bg;
    z-index: 1;
    box-shadow: 0 0 8px 0 $shadow;
}

.header-lower__position--right {
    .header-lower__logo-box {
        margin-left: auto;
        margin-right: $spacer;

        @media (min-width: $breakpoint) {
            margin-right: 0;
        }
    }
}

.header-lower__logo-img {
    display: block;
    height: 5rem;
    width: auto;
}

.header-lower__brand--link {
    position: relative;
    z-index: 2;
}

.header-lower__brand-img {
    padding-top: 0.5 * $padding;
    display: block;
    height: 2.5rem;
    width: auto;
}

.header-lower__nav-list--md {
    position: relative;
    bottom: -6 * $spacer;

    &:last-child {
        padding-right: $spacer;
    }
}

.header-lower__nav-list--sm {
    position: relative;
    top: 9 * $spacer;
    justify-content: flex-end;
    display: flex;

    &:last-child {
        padding-right: $spacer;
    }
}

.header-lower__nav-item {
    display: inline;
    margin-right: 1.5 * $spacer;
    line-height: 4rem;

    &:last-child {
        margin-right: 0;
    }

    .is-active {
        padding-bottom: 0.1em;
        border-bottom: 0.2em solid $brand-primary;
    }

    a,
    button {
        font-weight: bold;
        text-decoration: none;
    }
}

.header-lower__nav-link {
    display: inline;
    font-size: $font-size-md;

    &:hover {
        color: $brand-primary;
    }
}

.header-lower__nav-link:first-child {
    padding-left: 0;
}
