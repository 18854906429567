.rating-bar {
    display: inline-block;
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
    }
}

.rating-button {
    font-weight: normal;
    padding: 0 0.4em;
    margin-right: 0.2em;

    &.is-read-only,
    &:disabled {
        cursor: default;
    }
}

@mixin rating-button($color) {
    padding: 0.3em 0.6em;

    &.is-selected {
        color: $color;
    }

    &:hover,
    &:active,
    &:focus {
        background: $color;
        color: contrast-color($print-black);
    }

    i {
        margin-right: 0.3em;
    }

    &.is-read-only,
    &:disabled {
        color: $text-color;
        cursor: auto;
        background-color: $body-bg;
    }
}

.rating-up {
    @include rating-button($brand-primary);
}

.rating-down {
    @include rating-button($brand-danger);
}
